import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MiniHero from "../components/mini-hero"
import ContactForm from "../components/form"
const description  = 'We love meeting people and solving problems for our customers. Feel free to pop in or drop us an email. One of us can also pay you a visit or pop by for a chat.'
export default () => (
    <Layout>
        <SEO title="Visit us at our offie or drop us a line." description={description} keywords={[`gatsby`, `application`, `react`]} />
        <MiniHero title='Contact Us' />
        <ContactForm />
    </Layout>

    );
